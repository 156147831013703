import { dateZeroOfDay, dateToTimestamp } from "@/utils/date.js"
//根据委托单编号获取委托单的时间
export function getTimeFromBillCode(billCode) {
  const str = `20${billCode.slice(0, 6)}`.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
  let startTime = new Date(str)
    //将开始时间转换为时间戳
  let startTimetamp = dateZeroOfDay(startTime)
  let endTime = dateZeroOfDay(startTime, 8)
  return {
    startTime: startTimetamp,
    endTime: endTime
  }
}