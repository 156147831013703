<template>
  <div :class="$style['pt-distribute-task']">
    <validate-form tag="div" ref="form" :class="$style['formss']">
      <pt-form-item
        class="form-item"
        tag="div"
        name="开始日期"
        ref="username"
      >
        <div :class="[$style['form-item-box']]">
          <span>开始日期：</span>
          <pt-date-picker v-model="formValue.startTime" title="开始日期"></pt-date-picker>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="目标日期"
        ref="username"
      >
        <div :class="[$style['form-item-box']]">
          <span>目标日期：</span>
          <pt-date-picker v-model="formValue.endTime" title="目标日期"></pt-date-picker>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="实验人员"
        rules="required"
        ref="username"
      >
        <div :class="[$style['form-item-box'],$style['required']]">
          <span>实验人员：</span>
          <cube-select
            v-model="formValue.labTester"
            :options="options">
          </cube-select>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="备注"
      >
        <div :class="$style['form-item-box']">
          <span>备注：</span>
          <wt-input v-model="formValue.comment" placeholder="请填写备注">
          </wt-input>
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="handleDistribute" primary>确认分派</wt-button>
    </div>
    
  </div>
</template>

<script>

import {nextTrust} from '../status'
// import { next } from 'cps/lab-order/order-status/status'
import {dateZeroOfDay} from "@/utils/date.js"
import {getTimeFromBillCode} from "@/utils/page-formt/formt.js"
// 组件class前缀
const prefixCls = 'pt-distribute-task'

export default {
  name: 'pt-distribute-task',
  computed: {
    classes () {
      return `${prefixCls}`
    },
    id () {
      return this.$route.params.id
    },
    isTask () {
      return this.$route.query.isTask
    },
    orderId () {
      return this.$route.query.orderId
    }
  },

  data () {
    return {
      options:[],
      billDetail: null,
      formValue: {
      }
    }
  },
  watch:{
    'billDetail.billCode': {
      handler: function (val, old) {
        if (val !== old && val) {
          let {startTime, endTime} = getTimeFromBillCode(val)
          this.$set(this.formValue, 'startTime', startTime)
          this.$set(this.formValue, 'endTime', endTime)
          console.log('this.formValue', this.formValue)
          // const str = `20${val.slice(0, 6)}`.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
          // // this.form.startTime = new Date(str)
          // // this.form.endTime = dateZeroOfDay(this.form.startTime,7)
          // this.$set(this.formValue, 'endTime', dateZeroOfDay(this.formValue.startTime, 7))
        }
      }
    },
		'billDetail':{
			handler:function(val){
				this.formValue.comment=val.remarks
			},
			deep:true,
			immediate:true
		}
  },
  methods: {
    distributeOrderFromTask () {
      this.formValidata().then(() => {
        let data = {
          id: this.billId,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
          comment: this.form.comment,
          labTester: {
            name: this.currentTester.label,
            organizationUserId: this.currentTester.value
          }
        }
        this.loading = true
        this.$store.dispatch('distributeOrderFromTask', data).then(res => {
          this.$router.go(-1)
        }).catch(err => {
          this.$toast.error(err.message || err)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    distributeByOrder () {
      this.formValidata().then(() => {
        let data = {
          orderIds: [this.$route.query.orderId],
          billId: this.billId,
          action: 'ORDER_ASSIGN',
          orderStatusDetail: {
            action: 'ORDER_ASSIGN',
            productionTaskDetail: {
              startTime: this.form.startTime,
              endTime: this.form.endTime,
              comment: this.form.comment,
              labTester: {
                name: this.currentTester.label,
                organizationUserId: this.currentTester.value
              }
            }
          }
        }
        // return next.call(this, data)
        this.$loading()
        next.call(this, data).then(response => {
          this.$router.go(-1)
        }).catch(err => {
          this.$toast.error(err.message || err)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    handleDistribute () {
      // 样品详情中创建任务单
      if (this.$route.query.isFromOrder === 'true') {
        this.distributeByOrder()
        return
      }
      // 任务单详情中，勾选样品进行重新分派
      if (this.isTask === 'true') {
        this.distributeOrderFromTask()
        return
      }
      this.$refs.form.validate("formValue").then((validate) => {
        if(validate){
          const {id, firstName,signatureImageUrl} = JSON.parse(this.formValue.labTester)
          let data = {
            billId: this.id,
            action: 'BILL_ASSIGN',
            billStatusDetail: {
              action: 'BILL_ASSIGN',
              productionTaskDetail: {
                startTime: this.formValue.startTime,
                endTime: this.formValue.endTime,
                comment: this.formValue.comment,
                labTester: {
                  name: firstName,
                  organizationUserId: id,
									signatureImageUrl:signatureImageUrl
                }

              }
            }
          }
          this.$loading()
          nextTrust.call(this, data).then(response => {
            this.$router.go(-1)
          }).catch(err => {
            this.$toast.error(err.message || err)
          }).finally(() => {
            this.$loading.hide()
          })
        }
        
      });
    },
    getOrderCountByBill (billId) {
      this.$store.dispatch('getOrderCountByBill', billId).then(res => {
        this.billDetail = res.billDTO
      }).catch(err => {
        this.$toast.error(err || '操作失败')
      })
    },
    getOrderCountByTask (billId) {
      this.$store.dispatch('getOrderCountByTask', billId).then(res => {
        this.billDetail = res.billDTO
      }).catch(err => {
        this.$toast.error(err || '操作失败')
      })
    },
    getOptions () {
      let data = {
        role:["ROLE_RT_EX", "ROLE_EX"],
        pageable: {
          page: 0,
          size: 99999
        }
      }
      this.$store.dispatch('systemCustomerList', data).then(data => {

        this.options = data.map(ele => {
          return {
            text: ele.firstName,
            value: JSON.stringify(ele)
          }
        })
      })
    }
  },
  created () {
    this.getOptions()
    if (this.id) {
      this.isTask ? this.getOrderCountByTask(this.id) : this.getOrderCountByBill(this.id)
    } else {
      if(this.$route.query.billCode){
        this.billDetail = {billCode:this.$route.query.billCode }
      }
      
    }
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-distribute-task{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid @page-background-color;
  &.required{
    > span{
      position: relative;
      &::before{
        content: '*';
        position: absolute;
        color:red;
        left: -10px;
        top: 0;
        width: auto;
      }
    }
  }
  > span{
    white-space: nowrap;
    width: 80px;
    text-align: left;
  }
  > p{
    padding: 10px;
  }
  :global{
    .wt-input,
    .cube-select{
      flex: 1;
      text-align: left;
      &::after{
        border: none;
      }
    }
  }
}
</style>
