<template>
  <pt-distribute-task class="pt-distribute-task__withData"></pt-distribute-task>
</template>

<script>
import PtDistributeTask from './index'
export default {
  name: 'pt-distribute-task__withData',
  components: {
    'pt-distribute-task': PtDistributeTask
  }
}
</script>
