import { formatDateObject } from '@/utils/formatUtils/date.js'
// import { uploadBigFile } from 'utils/oss'
import store from '@/store'
import Vue from "vue";
const noFileAction = []
/**
     * 根据当前状态得到下个状态
     * @param {*string} currentStatus 当前状态
     * 支持回调，eg：next(***).then(修改成功后的操作).catch(修改失败后的操作)
     */
export function nextTrust (data, isLot = false) {

  for (let i in data) { // 处理下字段值格式，比如将日期序列化成字符串，以符合接口所需格式，
    /* global _ */
    if (_.isDate(data[i])) {
      data[i] = formatDateObject(data[i])
    }
  }
  return new Promise((resolve, reject) => {
    store.dispatch( isLot ? 'updateBatchBillStatus' :'updateBillStatus', data).then(response => {
      if(response.data.successful){
        if(data.action === 'BILL_CHECKED' && data.billStatusDetail.billStatusResult === 'APPROVED'){
          response.data.isPolling = true
        }
        resolve(response)
      }else{
        let info = new Set()
        if (response.data.message) {
          info.add(response.data.message)
        }
        if (response.data.status === 'NEED_APPROVAL') {
          info.add('进入待处理')
        }
        if (response.data.status === 'FAIL') {
          info.add('操作失败')
        }
        if (response.data.status === 'NOT_EXECUTED') {
          info.add('操作未执行')
        }
        if (response.data.status === 'WAIT_FOR_FUTURE_ACTION') {
          info.add('进入待办')
        }
        reject(Array.from(info).toString())
      }
    }).catch(err => {
      console.log('====err', err)
      Vue.prototype.$toast.error(err||'操作失败')
      reject(err)
    })
  })
}

// const orderStatusOpearteEnum = ['SUCCESS', 'FAIL', 'NOT_EXECUTED', 'VALIDATION_FAILED', 'NEED_APPROVAL', 'WAIT_FOR_FUTURE_ACTION']

const successOrderStatusOpearteEnum = ['SUCCESS']
const warnOrderStatusOpearteEnum = ['NEED_APPROVAL', 'WAIT_FOR_FUTURE_ACTION']
const errorOrderStatusOpearteEnum = ['FAIL', 'VALIDATION_FAILED', 'NOT_EXECUTED']

// 返回异常处理操作成功后，返回的message，应该用的eleemnt-ui message提示类型
function checkMessageType (status) {
  if (successOrderStatusOpearteEnum.find(ENUM => ENUM === status)) {
    return 'success'
  } else if (warnOrderStatusOpearteEnum.find(ENUM => ENUM === status)) {
    return 'warning'
  } else if (errorOrderStatusOpearteEnum.find(ENUM => ENUM === status)) {
    return 'error'
  }
}

function summaryAllTip (datas) {
  const statuses = datas.map(e => e.status)
  let successNum = statuses.filter(e => e === 'SUCCESS').length
  let warningNum = statuses.filter(e => warnOrderStatusOpearteEnum.includes(e)).length
  let errorNum = statuses.filter(e => errorOrderStatusOpearteEnum.includes(e)).length
  // ${warningNum ? `警告${warningNum}条，` : ''}
  return `共${datas.length}条处理记录，其中成功${successNum + warningNum}条，失败<span style="color:red">${errorNum}</span>条`
}

// {
// statusResult: '',
// taskId: '',
// attachment: {
//   ossUrl: '',
//   type: 'VERIFICATION_REQUEST',
//   name: 'file.name'
// }
// file:'可选，File类型，如果有文件请传入',
// sampleRecollectCost:'可选，如果是重采，请传入'0
// }
export async function handle (data = {
  statusResult: '',
  taskId: '',
  attachment: {
    ossUrl: '',
    type: 'VERIFICATION_REQUEST',
    name: 'file.name'
  }
}) {
  try {
    if (data.file) {
      // 有文件上传的操作
      // if (!data.attachment) {
      //   throw (new Error('文件上传必须同时包含file和attachment两个字段'))
      // }
      // let resourceUrl = await uploadBigFile('mutationVerify', data.file)
      // if (!resourceUrl) {
      //   throw (new Error('resourceUrl为空'))
      // }
      // data.attachment.ossUrl = resourceUrl
    } else if (data.sampleRecollectCost) {
      // 重采
      //   data.sampleRecollectCost = Number(data.sampleRecollectCost) * 100
    }
    let res = await store.dispatch('billPendingTasksSubmit', data)
    if (data.taskId) {
      // isDELETE表示是否是删除操作，加字段的原因是因为删除操作成功之后不能更新状态（删除的订单会报404），而是返回上一页
      return Promise.resolve({ type: checkMessageType(res.data.status), data: res.data, isDELETE: data.statusResult === 'DELETE' })
    }
    if (data.taskIds && data.taskIds.length) {
      return Promise.resolve({ summaryTip: summaryAllTip(res.data) })
    }
  } catch (error) {
    console.error(error)
    // this.loading = false
    Vue.prototype.$toast.error(err||'操作失败！')
    return Promise.reject(error)
  }
}
