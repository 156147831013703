<template>
  <pt-distribute-task class="pt-distribute-task__page"></pt-distribute-task>
</template>

<script>
import PtDistributeTask from 'cps/trust-order/pt-distribute-task/withData'
export default {
  name: 'pt-distribute-task__view',
  components: {
    'pt-distribute-task': PtDistributeTask
  }
}
</script>
